import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';

export const sortPaymentMethodsByRank = (paymentMethods: BuyNowPayLaterMethod[]): BuyNowPayLaterMethod[] =>
  paymentMethods.sort((aMethod, bMethod) => aMethod.rank - bMethod.rank);

export const getBiOfferId = (methods: BuyNowPayLaterMethod[]): string =>
  methods
    .map((pm) => pm.paymentMethod)
    .reverse()
    .join(',');

export const getPaymentProvider = (methods: BuyNowPayLaterMethod[]) =>
  methods
    .filter((pm) => pm.paymentProviderName)
    .map((pm) => pm.paymentProviderName)
    .reverse()
    .join(',');
