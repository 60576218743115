export function splitTextIntoTwoParts(
  str: string,
  params: {
    wordsFromEnd?: number;
    separator?: string;
  } = { wordsFromEnd: 1, separator: ' ' },
): {
  first: string;
  last: string;
} {
  const { wordsFromEnd, separator } = params;
  if (!str?.length || str.length < wordsFromEnd) {
    return {
      first: str,
      last: '',
    };
  }

  const strArr = str.split(separator);
  const last = strArr.splice(strArr.length - wordsFromEnd, strArr.length);

  if (strArr.length) {
    return {
      last: last.join(separator),
      first: strArr.join(separator),
    };
  }

  return {
    first: str,
    last: '',
  };
}
