import React from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { ThemeString } from '../../types/Theme';
import { DeviceTypeString } from '../../types/DeviceType';
import { withBiLogger, BiLogger } from '../../utils/biLogger';
import { isInModal } from '../../utils/isInModal';
import { PriceBreakdownView } from './view';

export interface PriceBreakdownControllerProps {
  dataHook: string;
  paymentMethod: PaymentMethod;
  logo?: React.ReactNode;
  theme: ThemeString;
  mainTitle: string;
  detailsPageUrl?: string;
  modalSize?: {
    mobile: {
      height: number;
      width: number;
    };
    desktop: {
      height: number;
      width: number;
    };
  };
  deviceType: DeviceTypeString;
  priceTitle?: string;
  biLogger: BiLogger;
  onDetailsClick?(): void;
  openModal?(url: string, width: number, height: number, onClose?: (payload?: any) => void): void;
}

export const PriceBreakdownController: React.FC<PriceBreakdownControllerProps> = ({
  dataHook,
  paymentMethod,
  logo,
  theme,
  mainTitle,
  detailsPageUrl,
  deviceType,
  modalSize,
  priceTitle,
  biLogger,
  openModal,
  onDetailsClick,
}) => {
  const hideDetailsButton = isInModal() || !detailsPageUrl || (onDetailsClick === undefined && openModal === undefined);

  const handleDetailsButtonClick = () => {
    biLogger?.report(
      installmentBannerClickedMoreInfo({
        paymentProvider: paymentMethod,
      }),
    );

    if (typeof onDetailsClick === 'function') {
      onDetailsClick();
      return;
    }

    openModal?.(detailsPageUrl, modalSize[deviceType].width, modalSize[deviceType].height);
  };

  return (
    <PriceBreakdownView
      dataHook={dataHook}
      theme={theme}
      logo={logo}
      mainTitle={mainTitle}
      onDetailsClick={handleDetailsButtonClick}
      priceTitle={priceTitle}
      withDetailsButton={!hideDetailsButton}
    />
  );
};

export const PriceBreakdown = withBiLogger<PriceBreakdownControllerProps>(PriceBreakdownController);
