import React, { useCallback } from 'react';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { DeviceTypeString } from '../../types/DeviceType';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { BiLogger, withBiLogger } from '../../utils/biLogger';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';
import { getModalSize } from '../../utils/getModalSize';
import { isInModal } from '../../utils/isInModal';
import { reverseMapSearch } from '../../utils/reverseMapSearch';
import { GenericPaymentMethodItemCollapsedView } from './view';

export interface GenericPaymentMethodItemCollapsedProps {
  bnplPayload?: BuyNowPayLaterMethod;
  deviceType?: DeviceTypeString;
  openModal?: OpenModal;
  biLogger: BiLogger;
}

export const GenericPaymentMethodItemCollapsedController: React.FC<GenericPaymentMethodItemCollapsedProps> = ({
  bnplPayload,
  deviceType,
  openModal,
  biLogger,
}) => {
  const sizeFromServer = bnplPayload.bnplModalPage?.resolution;
  const modalUrl = bnplPayload.bnplModalPage?.url;
  const modalSize = getModalSize(deviceType, bnplPayload.paymentMethod, sizeFromServer);

  /* Retrieving payment method name from enum key names */
  const paymentMethodName = reverseMapSearch(InstallmentPaymentMethod, bnplPayload.paymentMethod);

  const onCtaTextClick = useCallback(() => {
    biLogger.report(
      installmentBannerClickedMoreInfo({
        paymentProvider: bnplPayload.paymentMethod,
      }),
    );
    // Should not be clickable if is in modal
    openModal && modalUrl && !isInModal() && openModal(modalUrl, modalSize.width, modalSize.height, () => {});
  }, [openModal, modalUrl]);

  return <GenericPaymentMethodItemCollapsedView ctaText={paymentMethodName} onCtaTextClick={onCtaTextClick} />;
};

export const GenericPaymentMethodItemCollapsed = withBiLogger<GenericPaymentMethodItemCollapsedProps>(
  GenericPaymentMethodItemCollapsedController,
);
