import React from 'react';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { ThemeString } from '../../types/Theme';
import { PriceBreakdown } from '../PriceBreakdown';
import { DeviceTypeString } from '../../types/DeviceType';
import { DH } from '../../utils/DataHook';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { SEZZLE_MODAL_SIZE } from './utils/constants';
import { SezzleLogo } from './logo';

export interface SezzleViewProps {
  mainTitle: string;
  priceTitle: string;
  theme?: ThemeString;
  detailsPageUrl: string;
  deviceType: DeviceTypeString;
  logo?: React.ReactNode;
  openModal: OpenModal;
}

export const SezzleView: React.FC<SezzleViewProps> = ({
  mainTitle,
  priceTitle,
  theme,
  deviceType,
  detailsPageUrl,
  logo = <SezzleLogo theme={theme} />,
  openModal,
}) => {
  return (
    <PriceBreakdown
      dataHook={DH.PaymentMethodItem.Sezzle}
      paymentMethod={PaymentMethod.Sezzle}
      logo={logo}
      theme={theme}
      mainTitle={mainTitle}
      detailsPageUrl={detailsPageUrl}
      modalSize={SEZZLE_MODAL_SIZE}
      deviceType={deviceType}
      priceTitle={priceTitle}
      openModal={openModal}
    />
  );
};
