export type CurrencyString = string | Currency;

export enum Currency {
  AUD = 'AUD',
  USD = 'USD',
  NZD = 'NZD',
  GBP = 'GBP',
  CAD = 'CAD',
  EUR = 'EUR',
  HKD = 'HKD',
  ILS = 'ILS',
  MXN = 'MXN',
  INR = 'INR',
  SGD = 'SGD',
  MYR = 'MYR',
  BRL = 'BRL',
  PHP = 'PHP',
}
