import React from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { Theme, ThemeString } from '../../types/Theme';
import { DeviceType, DeviceTypeString } from '../../types/DeviceType';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { DH } from '../../utils/DataHook';
import { isInModal } from '../../utils/isInModal';
import { BiLogger, withBiLogger } from '../../utils/biLogger';
import { getDetailsPageUrl } from '../../utils/detailsPage';
import { getAffirmReactComponents } from './getAffirmReactComponents';
import s from './styles.scss';
import { AFFIRM_MODAL_SIZE } from './constants';

export interface AffirmDynamicControllerProps {
  publicBaseUrl: string;
  bnplPayload: BuyNowPayLaterMethod;
  deviceType?: DeviceTypeString;
  theme: ThemeString;
  openModal: OpenModal;
  biLogger: BiLogger;
}

export const AffirmDynamicController: React.FC<AffirmDynamicControllerProps> = ({
  deviceType = DeviceType.Desktop,
  theme = Theme.Light,
  openModal,
  bnplPayload,
  biLogger,
  publicBaseUrl,
}) => {
  const logoSrc = theme === Theme.Light ? bnplPayload?.logo.logoLight : bnplPayload?.logo.logoDark;

  const hideModalTrigger = isInModal() || openModal === undefined;

  const detailsPageUrl = getDetailsPageUrl(publicBaseUrl, encodeURIComponent(bnplPayload.infoUrl), deviceType);

  const AffirmElements = getAffirmReactComponents(bnplPayload.promoMessage, {
    logoSrc,
    deviceType,
    onModalTriggerClick() {
      biLogger.report(
        installmentBannerClickedMoreInfo({
          paymentProvider: PaymentMethod.Affirm,
        }),
      );
      openModal(detailsPageUrl, AFFIRM_MODAL_SIZE[deviceType].width, AFFIRM_MODAL_SIZE[deviceType].height, () => {});
    },
    hideModalTrigger,
  });
  return (
    <div data-hook={DH.PaymentMethodItem.Affirm} className={s.container}>
      {AffirmElements.map((AffirmElement, i) => (
        <AffirmElement key={i} />
      ))}
    </div>
  );
};
export const AffirmDynamic = withBiLogger<AffirmDynamicControllerProps>(AffirmDynamicController);
