import { InstallmentPaymentMethod } from './types/InstallmentPaymentMethod';

/*
 * This modal sizes are stored temporary, until make sure they are coming from server.
 * Was put here to reduce duplication, as it is used in different components.
 * */
const commonModalSize = {
  width: 770,
  height: 610,
};
type ModalSize = {
  [paymentMethod: string]: {
    width: number;
    height: number;
  };
};

export const DesktopModalSize: ModalSize = {
  [InstallmentPaymentMethod.Laybuy]: {
    width: 620,
    height: 525,
  },
  [InstallmentPaymentMethod.Affirm]: {
    width: 610,
    height: 610,
  },
  [InstallmentPaymentMethod.Afterpay]: commonModalSize,
  [InstallmentPaymentMethod.Clearpay]: commonModalSize,
  [InstallmentPaymentMethod.PayPal]: {
    width: 640,
    height: 620,
  },
  [InstallmentPaymentMethod.Sezzle]: {
    width: 620,
    height: 580,
  },
};

// workaround to open modal to 100%, openModal doesn't support string values
// https://wix.slack.com/archives/CAKBA7TDH/p1604926273350300?thread_ts=1604922926.343800&cid=CAKBA7TDH
export const MobileModalSize = {
  width: 2000,
  height: 2000,
};
