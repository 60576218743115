import React from 'react';
import { DH } from '../../utils/DataHook';
import s from './styles.scss';

export const NoWrapContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className={s.noWrapContainer}>{children}</div>;
};

export const Container = ({ dataHook, children }: { dataHook: string; children: React.ReactNode }) => {
  return (
    <div data-hook={dataHook} className={s.container}>
      {children}
    </div>
  );
};

export const LogoWrap = ({ children }: { children: React.ReactNode }) => {
  return (
    <span data-hook={DH.PaymentMethodView.Logo} className={s.logo}>
      {children}
    </span>
  );
};
