import React from 'react';
import { BiLogger } from './types';
import { BiLoggerContext } from './BiLoggerProvider';

export interface WithBiLoggerProps {
  biLogger: BiLogger;
}

export function withBiLogger<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentClass<Omit<P, keyof WithBiLoggerProps>> {
  class WithBiLogger extends React.Component<Omit<P, keyof WithBiLoggerProps>> {
    static displayName = `withBiLogger(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    render() {
      return (
        <BiLoggerContext.Consumer>
          {(biLogger) => <WrappedComponent biLogger={biLogger} {...(this.props as P)}></WrappedComponent>}
        </BiLoggerContext.Consumer>
      );
    }
  }
  return WithBiLogger;
}
