import { DeviceType } from '../../../types/DeviceType';
import { DesktopModalSize, MobileModalSize } from '../../../common.constants';
import { InstallmentPaymentMethod } from '../../../types/InstallmentPaymentMethod';

// TODO: consider getting from metadata
export const SEZZLE_INSTALLMENTS_NUMBER = 4;

export const SEZZLE_MODAL_SIZE = {
  [DeviceType.Desktop]: DesktopModalSize[InstallmentPaymentMethod.Sezzle],
  [DeviceType.Mobile]: MobileModalSize,
};
