import React from 'react';
import { installmentBannerLoadFinished, installmentBannerListLoaded } from '@wix/bi-logger-cashier-ugc/v2';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { ThemeString } from '../../types/Theme';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { DeviceTypeString } from '../../types/DeviceType';
import { CurrencyString } from '../../types/Currency';
import { withBiLogger, BiLogger } from '../../utils/biLogger';
import { Experiment } from '../../types/Experiment';
import { PaymentMethodsListView } from './view.expanded';
import { PaymentMethodsListViewCollapsed } from './view.collapsed';
import { getPaymentProvider, getBiOfferId, sortPaymentMethodsByRank } from './utils';

export interface PaymentMethodsListProps {
  bnplMethods: BuyNowPayLaterMethod[];
  amount: string;
  currency: CurrencyString;
  deviceType?: DeviceTypeString;
  theme: ThemeString;
  publicBaseUrl: string;
  locale: string;
  biLogger?: BiLogger;
  openModal: OpenModal;
  onEmpty?(): void;
}

export interface PaymentMethodsListState {
  sortedBnplMethods: BuyNowPayLaterMethod[];
  biOfferId: string;
  paymentProvider: string;
}

const COLLAPSED_VIEW_BREAKPOINT = 3;

class PaymentMethodsListController extends React.PureComponent<PaymentMethodsListProps, PaymentMethodsListState> {
  state: PaymentMethodsListState = {
    sortedBnplMethods: [],
    biOfferId: '',
    paymentProvider: '',
  };

  isExperimentalCollapsedViewEnabled = experimentsService.isEnabled(Experiment.EnableCollapsedView);

  static getDerivedStateFromProps(props: PaymentMethodsListProps) {
    const sortedBnplMethods = sortPaymentMethodsByRank(props.bnplMethods);
    return {
      sortedBnplMethods,
      biOfferId: getBiOfferId(sortedBnplMethods),
      paymentProvider: getPaymentProvider(sortedBnplMethods),
    };
  }

  componentDidMount() {
    this.props.biLogger?.report(
      installmentBannerLoadFinished({
        offerId: this.state.biOfferId,
        paymentProvider: this.state.paymentProvider,
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.biOfferId !== prevState.biOfferId) {
      this.props.biLogger?.report(
        installmentBannerListLoaded({
          offerId: this.state.biOfferId,
          paymentProvider: this.state.paymentProvider,
        }),
      );
    }
  }

  render() {
    const { amount, currency, deviceType, publicBaseUrl, theme, locale, openModal } = this.props;

    const { sortedBnplMethods } = this.state;

    if (!sortedBnplMethods.length) {
      return null;
    }

    if (this.isExperimentalCollapsedViewEnabled && sortedBnplMethods.length >= COLLAPSED_VIEW_BREAKPOINT) {
      return (
        <PaymentMethodsListViewCollapsed
          bnplMethods={sortedBnplMethods}
          amount={amount}
          currency={currency}
          deviceType={deviceType}
          theme={theme}
          publicBaseUrl={publicBaseUrl}
          locale={locale}
          openModal={openModal}
        />
      );
    }

    return (
      <PaymentMethodsListView
        bnplMethods={sortedBnplMethods}
        amount={amount}
        currency={currency}
        deviceType={deviceType}
        theme={theme}
        publicBaseUrl={publicBaseUrl}
        locale={locale}
        openModal={openModal}
      />
    );
  }
}

export const PaymentMethodsList = withBiLogger<PaymentMethodsListProps>(PaymentMethodsListController);
