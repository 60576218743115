import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import React from 'react';
import debounce from 'lodash.debounce';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { DeviceType } from '../../types/DeviceType';
import { PaymentMethodsBannerInternalProps } from '../../types/PaymentMethodsBannerProps';
import { Theme } from '../../types/Theme';
import { DH } from '../../utils/DataHook';
import { InteractionName } from '../../utils/fedopsLogger/InteractionName';
import { getInitExperimentsParams } from '../../utils/getInitExperimentsParams';
import { initI18n } from '../../utils/i18n';
import { reportError } from '../../utils/Sentry';
import { PaymentMethodsList } from '../PaymentMethodsList';
import { createBiLogger, BiLoggerProvider, BiLogger } from '../../utils/biLogger';
import { fetchBnplMethods } from '../../utils/serverAPI/fetchBnplMethods';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';

const DEBOUNCE_TIME = 200;

export interface PaymentMethodsBannerState {
  isLoading: boolean;
  isError: boolean;
  bnplMethods: BuyNowPayLaterMethod[];
}

export class PaymentMethodsBanner extends React.Component<
  PaymentMethodsBannerInternalProps,
  PaymentMethodsBannerState
> {
  static defaultProps: Partial<PaymentMethodsBannerInternalProps> = {
    theme: Theme.Light,
    deviceType: DeviceType.Desktop,
  };

  state: PaymentMethodsBannerState = {
    isLoading: true,
    isError: false,
    bnplMethods: [],
  };

  private _isMounted = true;
  private biLogger: BiLogger = null;

  private initI18nPromise: Promise<void>;
  private initExperimentsPromise: Promise<void>;
  private fullLoadDependencies: Promise<any>[] = [];

  constructor(props: PaymentMethodsBannerInternalProps) {
    super(props);

    this.biLogger = createBiLogger(props.biLoggerFactory);

    props.fedopsLogger?.interactionEnded(InteractionName.BannerComponentLoad);
    props.fedopsLogger?.interactionStarted(InteractionName.BannerRender);

    this.initI18nPromise = this.initI18next(props.locale);
    this.addFullLoadDependency(this.initI18nPromise);

    this.initExperimentsPromise = this.initExperiments();
    this.addFullLoadDependency(this.initExperimentsPromise);
  }

  componentDidMount() {
    this.addFullLoadDependency(this.fetchBnplMethodsAndSetToState());
    this.onFullLoad();
  }

  async componentDidUpdate(prevProps: PaymentMethodsBannerInternalProps): Promise<void> {
    if (prevProps.amount !== this.props.amount) {
      try {
        await this.fetchBnplMethodsAndSetToStateDebounced();

        if (!this.state.bnplMethods.length) {
          this.props.onEmpty?.();
        }
      } catch (e) {
        this.setStateSafe({
          isError: true,
        });
        reportError(e);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private fetchBnplMethodsAndSetToStateDebounced = debounce(this.fetchBnplMethodsAndSetToState, DEBOUNCE_TIME, {
    leading: true,
  });

  private async fetchBnplMethodsAndSetToState(): Promise<void> {
    const {
      amount,
      fedopsLogger,
      locale,
      currency,
      meta: { appDefId, appInstanceId },
    } = this.props;

    const bnplMethods = await fetchBnplMethods({
      appDefId,
      appInstanceId,
      amount,
      currency,
      fedopsLogger,
      language: locale,
      baseURL: '/',
      appInstance: this.props.meta.appInstance,
      revalidateAppInstance: this.props.revalidateAppInstance,
    });

    this.setStateSafe({
      bnplMethods,
    });
  }

  initExperiments = async () => {
    await experimentsService.init(getInitExperimentsParams(this.props));
  };

  initI18next = (locale: string) => {
    return initI18n(locale || 'en');
  };

  addFullLoadDependency = (dependency: Promise<any>) => {
    this.fullLoadDependencies.push(dependency);
  };

  onFullLoad = () => {
    return Promise.all(this.fullLoadDependencies)
      .then(() => {
        this.props.onFullLoad?.({
          paymentMethods: this.state.bnplMethods.map(
            (pm) => pm.paymentMethod as InstallmentPaymentMethod & PaymentMethod,
          ),
        });

        if (!this.state.bnplMethods.length) {
          this.props.onEmpty?.();
        }

        this.reportBannerFullLoad();
        this.setStateSafe({
          isLoading: false,
          isError: false,
        });
      })
      .catch((e) => {
        this.setStateSafe({
          isLoading: false,
          isError: true,
        });

        reportError(e);
      });
  };

  private setStateSafe(state: Partial<PaymentMethodsBannerState>, callback?: () => void) {
    if (this._isMounted) {
      this.setState(state as PaymentMethodsBannerState, callback);
    }
  }

  get bannerStaticsBaseUrl(): string {
    let baseUrl: string = this.props.bannerSettings.bannerStaticsBaseUrl;

    if (baseUrl) {
      if (baseUrl.startsWith('//')) {
        baseUrl = document.location.protocol + baseUrl;
      }

      return baseUrl;
    }

    return '/';
  }

  reportBannerFullLoad = () => {
    this.props.fedopsLogger?.interactionEnded(InteractionName.BannerRender);
  };

  renderMethodsList = () => {
    const { amount, currency, deviceType, theme, openModal, locale, onEmpty } = this.props;
    const { bnplMethods } = this.state;

    return (
      <PaymentMethodsList
        bnplMethods={bnplMethods}
        amount={amount}
        currency={currency}
        deviceType={deviceType}
        theme={theme}
        publicBaseUrl={this.bannerStaticsBaseUrl}
        openModal={openModal}
        locale={locale}
        onEmpty={onEmpty}
      />
    );
  };

  render() {
    const { isLoading, isError } = this.state;
    if (isLoading || isError) {
      return null;
    }

    return (
      <BiLoggerProvider biLogger={this.biLogger}>
        <div data-hook={DH.Banner}>{this.renderMethodsList()}</div>
      </BiLoggerProvider>
    );
  }
}
