import React from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { PaymentMethodsComponentLoaderProps } from '../../types/PaymentMethodsBannerProps';
import { initErrorReporter } from '../../utils/Sentry';
import { PaymentMethodsBanner } from './controller';

initErrorReporter();

export class PaymentMethodsBannerRoot extends React.Component<PaymentMethodsComponentLoaderProps> {
  render() {
    return (
      <ErrorBoundary>
        <PaymentMethodsBanner {...this.props} />
      </ErrorBoundary>
    );
  }
}
