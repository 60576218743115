import React from 'react';
import {
  LogoInline,
  CtaModalTrigger,
  MessageElementInline,
  NoWrapInline,
  Container,
  ProviderAnalytics,
} from './elements';

export interface GenericPaymentMethodItemViewProps {
  firstLineText: string;
  secondLineText: string;
  ctaText: string;
  logoSrc: string;
  providerImpressionUrl?: string;
  onCtaTextClick?(): void;
}

export const GenericPaymentMethodItemView: React.FC<GenericPaymentMethodItemViewProps> = ({
  logoSrc,
  onCtaTextClick,
  firstLineText,
  ctaText,
  secondLineText,
  providerImpressionUrl,
}) => {
  return (
    <Container>
      <MessageElementInline>{firstLineText}</MessageElementInline>
      <NoWrapInline>
        <MessageElementInline>{secondLineText}</MessageElementInline>
        <LogoInline src={logoSrc} />
        {onCtaTextClick && ctaText && <CtaModalTrigger ctaText={ctaText} onCtaTextClick={onCtaTextClick} />}
      </NoWrapInline>
      <ProviderAnalytics url={providerImpressionUrl} />
    </Container>
  );
};
