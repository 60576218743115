import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';

export enum InstallmentPaymentMethod {
  Afterpay = PaymentMethod.Afterpay,
  Affirm = PaymentMethod.Affirm,
  Clearpay = PaymentMethod.Clearpay,
  Laybuy = PaymentMethod.Laybuy,
  Sezzle = PaymentMethod.Sezzle,
  Atome = PaymentMethod.Atome,
  CreditCard = PaymentMethod.CreditCard,
  PayPal = PaymentMethod.PayPal,
}
