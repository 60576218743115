const PM_ITEM_PREFIX = 'pmb-method-item';

export const DH = {
  Banner: 'payment-methods-banner',
  PaymentMethodView: {
    Logo: 'pmb-method-logo',
    Details: 'pmb-details-icon',
    ModalTrigger: 'pmb-modal-trigger',
    CollapsedView: 'pmb-collapsed-view',
    ExpandedView: 'pmb-expanded-view',
  },
  PaymentMethodItem: {
    MethodPrefix: PM_ITEM_PREFIX,
    Laybuy: `${PM_ITEM_PREFIX}-laybuy`,
    Afterpay: `${PM_ITEM_PREFIX}-afteray`,
    Affirm: `${PM_ITEM_PREFIX}-affirm`,
    Clearpay: `${PM_ITEM_PREFIX}-clearpay`,
    Sezzle: `${PM_ITEM_PREFIX}-sezzle`,
    Atome: `${PM_ITEM_PREFIX}-atome`,
    CreditCardInstallments: `${PM_ITEM_PREFIX}-credit-card`,
    GenericPaymentMethodItem: `${PM_ITEM_PREFIX}-generic`,
  },
};
