import { DeviceTypeString, DeviceType } from '../types/DeviceType';

export const DETAILS_PATH = 'assets/pages/details-page.html';

export const getDetailsPageUrl = (
  host = '',
  contentUrl: string,
  deviceType: DeviceTypeString = DeviceType.Desktop,
): string => {
  return `${host}${host.endsWith('/') ? '' : '/'}${DETAILS_PATH}?contentUrl=${contentUrl}&deviceType=${deviceType}`;
};
