export const isInModal = () => {
  const queryParams = getUrlParams(window.location);
  return !!queryParams && queryParams.isInModal === 'true';
};

const getUrlParams = (location) => {
  const hashes = location.search.slice(location.search.indexOf('?') + 1).split('&');
  return hashes.reduce(function (params, hash) {
    const keyValue = hash.split('=');
    params[keyValue[0]] = decodeURIComponent(keyValue[1]);
    return params;
  }, {});
};
