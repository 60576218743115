import * as Sentry from '@sentry/browser';
import { BANNER_BUNDLE_FILE_NAME } from '../../lazy/config';
import { sentryConfig } from './config';

// Given that Banner code is executed in TPAs iframes OR Viewer top frame (OOI context)
// using Sentry directly (without creating a client) might cause internal Sentry issues
// Case study: [at the time of writing] Viewer top frame provides Sentry v4, and Widget's
// Sentry v5 freaks out and loops itself into infinity, freezing the page
const sentryClient = new Sentry.Hub();

const isBannerEvent = (event: Sentry.Event): boolean => {
  try {
    const stacktrace = event.stacktrace || event.exception?.values[0]?.stacktrace;
    const frames = stacktrace?.frames || [];

    return frames[frames.length - 1].filename.includes(BANNER_BUNDLE_FILE_NAME);
  } catch (error) {
    return false;
  }
};

export const initErrorReporter = () => {
  sentryClient.bindClient(
    new Sentry.BrowserClient({
      ...sentryConfig,

      beforeSend(event) {
        if (!isBannerEvent(event)) {
          return null;
        }

        return event;
      },
    }),
  );
};

export const reportError = (error: Error | string) => {
  try {
    if (error) {
      if (typeof error === 'string') {
        sentryClient.captureMessage(error);
      } else if (error instanceof Error) {
        sentryClient.captureException(error);
      }
    }
  } catch (e) {
    console.error('Failed to exec reportError', e);
  }
};
