import React from 'react';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { DeviceTypeString } from '../../types/DeviceType';
import { ThemeString } from '../../types/Theme';
import { DH } from '../../utils/DataHook';
import { PriceBreakdown } from '../PriceBreakdown';
import { AtomeLogo } from './logo';

export interface AtomeViewProps {
  mainTitle: string;
  priceTitle: string;
  theme?: ThemeString;
  deviceType: DeviceTypeString;
}

export const AtomeView: React.FC<AtomeViewProps> = ({ mainTitle, priceTitle, theme, deviceType }) => {
  return (
    <PriceBreakdown
      dataHook={DH.PaymentMethodItem.Atome}
      paymentMethod={PaymentMethod.Atome}
      logo={<AtomeLogo theme={theme} />}
      theme={theme}
      mainTitle={mainTitle}
      deviceType={deviceType}
      priceTitle={priceTitle}
    />
  );
};
