import React from 'react';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { CurrencyString } from '../../types/Currency';
import { DeviceType, DeviceTypeString } from '../../types/DeviceType';
import { Theme, ThemeString } from '../../types/Theme';
import { prettyNumber } from '../../utils/amount';
import { AtomeView } from './view';

export interface AtomeControllerProps {
  bnplPayload?: BuyNowPayLaterMethod;
  currency: CurrencyString;
  deviceType?: DeviceTypeString;
  theme: ThemeString;
}

export const AtomeController: React.FC<AtomeControllerProps> = ({
  bnplPayload,
  currency,
  deviceType = DeviceType.Desktop,
  theme = Theme.Light,
}) => {
  const { t } = useTranslation();
  const pOption = bnplPayload.paymentOptions[0];
  if (!pOption) {
    return null;
  }
  const mainTitle = t('atome.view.mainTitle');
  const priceTitle = t('atome.view.priceTitle', {
    installment: prettyNumber(pOption.installmentAmount),
    currency: getSymbolFromCurrency(currency),
  });

  return <AtomeView mainTitle={mainTitle} priceTitle={priceTitle} theme={theme} deviceType={deviceType} />;
};
