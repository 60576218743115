import React from 'react';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { ThemeString } from '../../types/Theme';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { PriceBreakdown } from '../PriceBreakdown';
import { DeviceTypeString } from '../../types/DeviceType';
import { DH } from '../../utils/DataHook';
import { LAYBUY_MODAL_SIZE } from './constants';
import { LaybuyLogo } from './logo';

export interface LaybuyViewProps {
  mainTitle: string;
  priceTitle: string;
  theme?: ThemeString;
  detailsPageUrl: string;
  deviceType: DeviceTypeString;
  logo?: React.ReactNode;
  openModal: OpenModal;
}

export const LaybuyView: React.FC<LaybuyViewProps> = ({
  mainTitle,
  priceTitle,
  theme,
  deviceType,
  detailsPageUrl,
  logo = <LaybuyLogo theme={theme} />,
  openModal,
}) => {
  return (
    <PriceBreakdown
      dataHook={DH.PaymentMethodItem.Laybuy}
      paymentMethod={PaymentMethod.Laybuy}
      logo={logo}
      theme={theme}
      mainTitle={mainTitle}
      detailsPageUrl={detailsPageUrl}
      modalSize={LAYBUY_MODAL_SIZE}
      deviceType={deviceType}
      priceTitle={priceTitle}
      openModal={openModal}
    />
  );
};
