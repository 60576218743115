import { ExperimentsInitParams } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { PaymentMethodsBannerInternalProps } from '../types/PaymentMethodsBannerProps';

export const getInitExperimentsParams = (props: PaymentMethodsBannerInternalProps): ExperimentsInitParams => {
  const { experiments } = props.bannerSettings;
  const { visitorId, siteOwnerId } = props.meta;

  const initParams: ExperimentsInitParams = {};

  if (experiments && Object.keys(experiments).length !== 0) {
    initParams.experiments = experiments;
  }

  if (visitorId && siteOwnerId) {
    initParams.forSiteVisitors = {
      visitorId,
      siteOwnerId,
    };
  }

  return initParams;
};
