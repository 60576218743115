import React from 'react';
import { ThemeString } from '../../types/Theme';
import s from './logoStyles.scss';

export const LaybuyLogo: React.FC<{ theme: ThemeString }> = ({ theme }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="71" height="20" viewBox="0 0 71 20">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                className={s[theme]}
                d="M26.267 11.717H22.61V5.581c0-.136-.112-.253-.243-.253-.13 0-.243.117-.243.253v6.35c0 .137.112.255.243.255h3.9c.122 0 .225-.108.225-.234s-.1-.23-.225-.234m3.095-1.9l1.731-3.945 1.72 3.945h-3.45zm2.077-4.276c-.065-.146-.159-.243-.327-.243h-.019c-.168 0-.262.097-.327.243l-2.797 6.312c-.023.049-.036.102-.038.156 0 .117.104.214.225.214.112 0 .188-.068.234-.185l.767-1.763h3.863l.767 1.753c.046.108.13.195.242.195.13 0 .234-.104.234-.233 0-.055-.012-.108-.037-.156L31.44 5.542zm8.755-.214c-.103 0-.16.068-.216.146L37.593 9.03l-2.376-3.556c-.056-.087-.121-.146-.224-.146-.138.004-.249.115-.253.253 0 .049.02.107.047.156l2.554 3.75v2.484c0 .137.112.253.243.253.13 0 .243-.116.243-.253V9.477l2.535-3.71c.037-.06.075-.127.075-.196-.008-.131-.113-.236-.244-.243m6.126 4.802c0 .507-.383.74-1.02.74h-1.711v-1.5h1.664c.74 0 1.067.283 1.067.74v.02zm-2.731-3.448h1.421c.608 0 .945.253.945.701v.02c0 .506-.402.72-1.038.72h-1.328v-1.44zm2.936 1.938c.468-.272.861-.7.861-1.47v-.02c0-.467-.15-.847-.449-1.159-.374-.39-.963-.604-1.712-.604h-2.319c-.402 0-.72.331-.72.75v5.319c0 .418.318.75.72.75H45.3c1.477 0 2.45-.624 2.45-1.87v-.02c0-.916-.468-1.374-1.226-1.676zm7.763-3.312c-.402 0-.72.332-.72.75v3.215c0 1.081-.533 1.637-1.412 1.637-.88 0-1.413-.575-1.413-1.685V6.058c0-.42-.318-.75-.72-.75-.402 0-.72.33-.72.75v3.205c0 2.006 1.076 3.029 2.834 3.029s2.87-1.013 2.87-3.078V6.058c0-.42-.317-.75-.719-.75m7.407-.01c-.3 0-.514.175-.692.448l-1.618 2.406-1.59-2.387c-.178-.272-.383-.457-.71-.457-.403 0-.721.321-.721.72 0 .215.075.39.178.536l2.114 2.99v1.939c0 .419.317.75.72.75.402 0 .72-.331.72-.75V9.525l2.114-2.97c.093-.137.187-.322.187-.526 0-.41-.262-.731-.702-.731"
                transform="translate(-333 -108) translate(0 96) translate(333 12) translate(4 2)"
              />
              <path
                fill="#776DFF"
                d="M10.216 1.267l-1.564 1.63c-.112.116-.265 0-.265 0L6.384.772C6.079.453 5.656.27 5.214.27c-.443 0-.866.182-1.17.503-.644.67-.644 1.767 0 2.437l3.323 3.462c.305.32.728.502 1.17.502.443 0 .866-.182 1.17-.502l2.781-2.897.488-.521c.157-.166.375-.26.604-.26.228 0 .447.094.604.26.334.348.326.883-.008 1.23l-.501.527-5.053 5.263c-.155.16-.304 0-.304 0L2.823 4.552c-.305-.32-.728-.502-1.17-.502-.442 0-.865.181-1.17.502-.644.67-.644 1.767 0 2.437l6.85 7.135-.036-.033c.305.32.728.502 1.17.502.442 0 .866-.181 1.17-.502l6.378-6.643.068-.07c1.623-1.69 1.631-4.421.009-6.11-1.623-1.69-4.254-1.69-5.876 0"
                transform="translate(-333 -108) translate(0 96) translate(333 12) translate(4 2)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
