import React from 'react';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { Theme, ThemeString } from '../../types/Theme';
import { DeviceType, DeviceTypeString } from '../../types/DeviceType';
import { CurrencyString } from '../../types/Currency';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { prettyNumber } from '../../utils/amount';
import { SezzleView } from './view';

export interface SezzleControllerProps {
  bnplPayload?: BuyNowPayLaterMethod;
  currency: CurrencyString;
  deviceType?: DeviceTypeString;
  theme: ThemeString;
  openModal: OpenModal;
}

export const SezzleController: React.FC<SezzleControllerProps> = ({
  bnplPayload,
  currency,
  deviceType = DeviceType.Desktop,
  theme = Theme.Light,
  openModal,
}) => {
  const { t } = useTranslation();
  const detailsPageUrl = bnplPayload.infoUrl;
  const pOption = bnplPayload.paymentOptions[0];
  if (!pOption) {
    return null;
  }
  const mainTitle = t('sezzle.view.mainTitle');
  const priceTitle = t('sezzle.view.priceTitle', {
    installment: prettyNumber(pOption.installmentAmount),
    currency: getSymbolFromCurrency(currency),
  });
  const logo = theme === 'dark' ? bnplPayload.logo.logoDark : bnplPayload.logo.logoLight;
  return (
    <SezzleView
      mainTitle={mainTitle}
      priceTitle={priceTitle}
      theme={theme}
      detailsPageUrl={detailsPageUrl}
      deviceType={deviceType}
      logo={<img src={logo} alt=""></img>}
      openModal={openModal}
    />
  );
};
