import React, { useCallback, useMemo } from 'react';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { DeviceTypeString } from '../../types/DeviceType';
import { Theme, ThemeString } from '../../types/Theme';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { BiLogger, withBiLogger } from '../../utils/biLogger';
import { getModalSize } from '../../utils/getModalSize';
import { GenericPaymentMethodItemView } from './view';
import { splitTextIntoTwoParts } from './utils';

export interface GenericPaymentMethodItemProps {
  bnplPayload?: BuyNowPayLaterMethod;
  deviceType?: DeviceTypeString;
  theme: ThemeString;
  openModal?: OpenModal;
  biLogger: BiLogger;
  paymentMethod: PaymentMethod;
}

export const GenericPaymentMethodItemController: React.FC<GenericPaymentMethodItemProps> = ({
  bnplPayload,
  deviceType,
  theme,
  openModal,
  biLogger,
  paymentMethod,
}) => {
  const sizeFromServer = bnplPayload.bnplModalPage?.resolution;
  const modalUrl = bnplPayload.bnplModalPage?.url;

  const logoSrc = theme === Theme.Light ? bnplPayload?.logo.logoLight : bnplPayload?.logo.logoDark;

  const modalSize = getModalSize(deviceType, paymentMethod, sizeFromServer);

  const onCtaTextClick = useCallback(() => {
    biLogger.report(
      installmentBannerClickedMoreInfo({
        paymentProvider: paymentMethod,
      }),
    );

    openModal && modalUrl && openModal(modalUrl, modalSize.width, modalSize.height, () => {});
  }, [openModal, modalUrl]);

  // We don't want the logo of the payment method to fall back on the next line without a context
  // Therefore we'll ensure it will be following with the last two word of the bnpl message (e.g. of $100.00).
  const { first, last } = useMemo(() => splitTextIntoTwoParts(bnplPayload.bnplMessage), [bnplPayload.bnplMessage]);

  return (
    <GenericPaymentMethodItemView
      firstLineText={first}
      secondLineText={last}
      ctaText={bnplPayload.bnplModalPage?.ctaText}
      logoSrc={logoSrc}
      onCtaTextClick={openModal ? onCtaTextClick : undefined}
      providerImpressionUrl={bnplPayload?.providerImpressionUrl}
    />
  );
};

export const GenericPaymentMethodItem = withBiLogger<GenericPaymentMethodItemProps>(GenericPaymentMethodItemController);
