import React from 'react';
import { DeviceTypeString } from '../../types/DeviceType';
import { DH } from '../../utils/DataHook';
import { LogoWrap } from '../PriceBreakdown/elements';
import s from './styles.scss';

export type AffirmMessageItem = TextItem | LogoItem | ModalTriggerItem;

export type TextItem = {
  type: 'text';
  content: string;
};
export type LogoItem = {
  type: 'logo';
};
export type ModalTriggerItem = {
  type: 'modal-trigger';
  content: string;
};

export interface AffirmElementsProps {
  logoSrc: string;
  onModalTriggerClick(): void;
  hideModalTrigger: boolean;
  deviceType: DeviceTypeString;
}

export const getAffirmReactComponents = (msg: string, props: AffirmElementsProps): (() => React.ReactElement)[] => {
  try {
    return affirmItemsToReactElems(generateAffirmMessageItems(msg), props);
  } catch (e) {
    return [];
  }
};

/**
 * Builds React elements from Affirm Message Items
 * @param {AffirmMessageItem []} items
 * @param {AffirmElementsProps} props
 * @returns React Elements
 */
export function affirmItemsToReactElems(items: AffirmMessageItem[], props: AffirmElementsProps) {
  const getComponent = (item: AffirmMessageItem) => {
    switch (item.type) {
      case 'text':
        return () => {
          return <span className={s.nowrap}>{item.content}</span>;
        };
      case 'logo':
        return () => (
          <LogoWrap>
            <img src={props.logoSrc} className={s.logo} alt=""></img>
          </LogoWrap>
        );
      case 'modal-trigger': {
        if (props.hideModalTrigger) {
          return () => {
            return null;
          };
        }
        const cn = props.deviceType === 'desktop' ? `${s.modalTrigger} ${s.extraSpace}` : s.modalTrigger;

        return () => {
          return (
            <span data-hook={DH.PaymentMethodView.ModalTrigger} onClick={props.onModalTriggerClick} className={cn}>
              {item.content}
            </span>
          );
        };
      }
      default:
        return () => null;
    }
  };
  return items.map((item) => getComponent(item));
}

const ELEMENTS_TO_IGNORE = ['SCRIPT', 'STYLE', 'LINK'];
/**
 * Splits HTML string (response from Affirm's API) to building items
 * that will be used for manipulating.
 * @param {String} str HTML
 * @returns {AffirmMessageItem[]}
 */
export function generateAffirmMessageItems(str: string): AffirmMessageItem[] {
  const root = document.createElement('div');
  root.innerHTML = str;
  const nodes = root.childNodes;

  const getItem = (node: Node | Element) => {
    switch (node.nodeType) {
      case Node.TEXT_NODE:
        return { type: 'text', content: node.nodeValue };
      case Node.ELEMENT_NODE: {
        const element = node as Element;
        if (ELEMENTS_TO_IGNORE.includes(element.tagName.toUpperCase())) {
          return null;
        }

        if (element.classList.contains('__affirm-logo')) {
          return { type: 'logo' };
        }

        if (element.classList.contains('affirm-modal-trigger')) {
          return { type: 'modal-trigger', content: element.innerHTML };
        }

        if (element.hasChildNodes()) {
          return Array.prototype.map.call(element.childNodes, getItem);
        }
        return null;
      }
      default:
        return null;
    }
  };
  const items = Array.prototype.map.call(nodes, getItem);
  return sanitizeItems(flatten(items));
}

/**
 * Combines sibling text items into one, skip empty text items.
 * @param {T extends AffirmMessageItem[]} arr
 * @returns {T extends AffirmMessageItem[]} new array
 */
export const sanitizeItems = <T extends AffirmMessageItem>(arr: T[]): T[] => {
  return arr.reduce((acc, i) => {
    if (!i) {
      return acc;
    }
    if (i.type !== 'text') {
      acc.push(i);
      return acc;
    }
    const item = i as TextItem;
    if (acc.length > 0 && acc[acc.length - 1].type === 'text') {
      acc[acc.length - 1].content += item.content;
      return acc;
    }
    acc.push(i);
    return acc;
  }, []);
};

/**
 * Flatten array
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#use_a_stack
 * @param {T extends unknown[]} arr
 * @returns {T extends unknown[]} arr
 */
export const flatten = <T extends unknown>(input: T[]): T[] => {
  const stack = [...input];
  const res = [];
  while (stack.length) {
    const next = stack.pop();
    if (Array.isArray(next)) {
      stack.push(...next);
    } else {
      res.push(next);
    }
  }
  return res.reverse();
};
