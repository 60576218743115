import { DeviceType } from '../../types/DeviceType';
import { Currency } from '../../types/Currency';
import { DesktopModalSize, MobileModalSize } from '../../common.constants';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';

export const MaxBoostInstallment = {
  [Currency.AUD]: 240,
  [Currency.NZD]: 240,
  [Currency.GBP]: 120,
};

export const BoostPaymentThreshold = {
  [Currency.NZD]: 1440,
  [Currency.AUD]: 1440,
  [Currency.GBP]: 720,
};

export const LAYBUY_MODAL_SIZE = {
  [DeviceType.Desktop]: DesktopModalSize[InstallmentPaymentMethod.Laybuy],
  [DeviceType.Mobile]: MobileModalSize,
};
