import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { DH } from '../../utils/DataHook';
import { CurrencyString } from '../../types/Currency';
import { Container, NoWrapContainer } from '../PriceBreakdown/elements';
import { prettyNumber } from '../../utils/amount';

export interface Props {
  bnplPayload?: BuyNowPayLaterMethod;
  currency: CurrencyString;
}

export const CreditCardInstallments: React.FC<Props> = ({ currency, bnplPayload }) => {
  const { t } = useTranslation();
  if (bnplPayload.paymentOptions.length === 0) {
    return null;
  }
  const title = t('creditCardInstallments.view.title', {
    currency: getSymbolFromCurrency(currency),
    maxInstallments: bnplPayload.paymentOptions[0].installmentCount,
    minTransactionAmount: prettyNumber(bnplPayload.paymentOptions[0].installmentAmount),
  });
  return (
    <Container dataHook={DH.PaymentMethodItem.CreditCardInstallments}>
      <NoWrapContainer>{title}</NoWrapContainer>
    </Container>
  );
};
CreditCardInstallments.displayName = 'CreditCardInstallments';
