import React from 'react';
import { ThemeString } from '../../types/Theme';
import { DH } from '../../utils/DataHook';
import { DetailsIcon } from './DetailsIcon';
import { Container, LogoWrap, NoWrapContainer } from './elements';
import s from './styles.scss';

interface PriceBreakdownViewProps {
  dataHook: string;
  logo?: React.ReactNode;
  theme: ThemeString;
  mainTitle: string;
  priceTitle?: string;
  withDetailsButton: boolean;
  onDetailsClick?(): void;
}

export const PriceBreakdownView: React.FC<PriceBreakdownViewProps> = ({
  dataHook,
  logo,
  theme,
  mainTitle,
  priceTitle,
  withDetailsButton,
  onDetailsClick,
}) => {
  return (
    <Container dataHook={dataHook}>
      <NoWrapContainer>{mainTitle}</NoWrapContainer>
      <NoWrapContainer>
        {priceTitle && <span>{priceTitle}</span>}
        {logo && <LogoWrap>{logo}</LogoWrap>}
        {withDetailsButton && (
          <button data-hook={DH.PaymentMethodView.Details} className={s.infoButton} onClick={onDetailsClick}>
            <DetailsIcon />
          </button>
        )}
      </NoWrapContainer>
    </Container>
  );
};
