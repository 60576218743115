import React from 'react';
import { CtaModalTrigger, NoWrapInline, Container } from './elements';

export interface GenericPaymentMethodItemCollapsedViewProps {
  ctaText: string;
  onCtaTextClick?(): void;
}

export const GenericPaymentMethodItemCollapsedView: React.FC<GenericPaymentMethodItemCollapsedViewProps> = ({
  onCtaTextClick,
  ctaText,
}) => {
  if (!ctaText) {
    return null;
  }

  return (
    <Container>
      {onCtaTextClick ? (
        <CtaModalTrigger ctaText={ctaText} onCtaTextClick={onCtaTextClick} />
      ) : (
        <NoWrapInline>{ctaText}</NoWrapInline>
      )}
    </Container>
  );
};
