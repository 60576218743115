import React from 'react';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { Laybuy } from '../Laybuy';
import { AffirmDynamic } from '../AffirmDynamic';
import { CreditCardInstallments } from '../CreditCardInstallments';
import { Sezzle } from '../Sezzle';
import { ThemeString } from '../../types/Theme';
import { OpenModal } from '../../types/PaymentMethodsBannerProps';
import { DeviceTypeString } from '../../types/DeviceType';
import { CurrencyString } from '../../types/Currency';
import { Atome } from '../Atome';
import { GenericPaymentMethodItem } from '../GenericPaymentMethodItem';

export interface PaymentMethodItemProps {
  bnplPayload?: BuyNowPayLaterMethod;
  publicBaseUrl: string;
  paymentMethod: PaymentMethod;
  amount: string;
  currency: CurrencyString;
  deviceType: DeviceTypeString;
  theme: ThemeString;
  locale: string;
  openModal: OpenModal;
}

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = (props) => {
  switch (props.paymentMethod) {
    case PaymentMethod.Laybuy: {
      return <Laybuy {...props} />;
    }
    case PaymentMethod.Sezzle: {
      return <Sezzle {...props} />;
    }
    case PaymentMethod.CreditCard: {
      return <CreditCardInstallments {...props} />;
    }
    case PaymentMethod.Affirm: {
      return (
        <AffirmDynamic
          bnplPayload={props.bnplPayload}
          publicBaseUrl={props.publicBaseUrl}
          theme={props.theme}
          deviceType={props.deviceType}
          openModal={props.openModal}
        />
      );
    }
    case PaymentMethod.Atome: {
      return <Atome {...props} />;
    }
    default: {
      return <GenericPaymentMethodItem {...props} />;
    }
  }
};
PaymentMethodItem.displayName = 'PaymentMethodItem';
