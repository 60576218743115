import React from 'react';
import { Trans } from 'react-i18next';
import { GenericPaymentMethodItemCollapsed } from '../GenericPaymentMethodItemCollapsed';
import { DH } from '../../utils/DataHook';
import { PaymentMethodsListProps } from './types';
import s from './styles.scss';

export const PaymentMethodsListViewCollapsed: React.FC<PaymentMethodsListProps> = ({
  bnplMethods,
  deviceType,
  openModal,
}) => {
  const facingMethods = bnplMethods.slice(0, -1);
  const trailingMethod = bnplMethods.slice(-1)[0];

  return (
    <div className={s.container} data-hook={DH.PaymentMethodView.CollapsedView}>
      <Trans i18nKey={'generic.view.breakdownMessage'}>
        or buy now, pay later with
        {/* @ts-expect-error require to suppress missing typing error*/}
        <span i18nisdynamiclist="true">
          {facingMethods.map((pm, index) => (
            <React.Fragment key={pm.paymentMethod}>
              <GenericPaymentMethodItemCollapsed bnplPayload={pm} deviceType={deviceType} openModal={openModal} />
              {index < facingMethods.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </span>
        <GenericPaymentMethodItemCollapsed bnplPayload={trailingMethod} deviceType={deviceType} openModal={openModal} />
      </Trans>
    </div>
  );
};
PaymentMethodsListViewCollapsed.displayName = 'PaymentMethodsListViewCollapsed';
