import React from 'react';
import { DH } from '../../utils/DataHook';
import s from './styles.scss';

export const NoWrapInline: React.FC = ({ children }) => <span className={s.noWrap}>{children}</span>;

export const CtaModalTrigger: React.FC<{
  ctaText: string;
  onCtaTextClick(): void;
}> = ({ ctaText, onCtaTextClick }) => (
  <button data-hook={DH.PaymentMethodView.ModalTrigger} className={s.ctaLink} onClick={onCtaTextClick}>
    {ctaText}
  </button>
);

export const Container: React.FC = ({ children }) => (
  <span className={s.noWrap} data-hook={DH.PaymentMethodItem.GenericPaymentMethodItem}>
    {children}
  </span>
);
