import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import React from 'react';
import { DH } from '../../utils/DataHook';
import { PaymentMethodItem } from './elements';
import { PaymentMethodsListProps } from './types';

export const PaymentMethodsListView: React.FC<PaymentMethodsListProps> = ({
  bnplMethods,
  amount,
  currency,
  deviceType,
  theme,
  publicBaseUrl,
  openModal,
  locale,
}) => (
  <div data-hook={DH.PaymentMethodView.ExpandedView}>
    {bnplMethods.map((pm) => (
      <PaymentMethodItem
        key={pm.paymentMethod}
        paymentMethod={pm.paymentMethod as PaymentMethod}
        bnplPayload={pm}
        amount={amount}
        currency={currency}
        deviceType={deviceType}
        theme={theme}
        publicBaseUrl={publicBaseUrl}
        openModal={openModal}
        locale={locale}
      />
    ))}
  </div>
);
PaymentMethodsListView.displayName = 'PaymentMethodsListView';
